:root{
  --purple-gradient: linear-gradient(180deg, #4C4452 0%, #312A36 100%);
  --black-gradient: linear-gradient(180deg, #272727 0%, #212121 100%);
  --white: #FFFFFF;
  --text-white: rgba(255, 255, 255, .9);
  --purple: #AB9CB8;
  --light-purple: #A194AC;
  --dark-purple: #8869A8;
  --accent: #C893FF;
  --black: #212121;
}
* {
  margin: 0;
  padding-bottom: 0;
  font-family: "Outfit", sans-serif;
  font-style: normal;
  box-sizing: border-box;
}
body{
  overflow-x: hidden;
  min-height: 100vh;
  background: var(--purple-gradient) no-repeat;
}
button{
  outline: none;
  display: flex;
  align-items: center;
  padding: 11.5px 20px;
  font-weight: 500;
  font-size: 14px;
  line-height: 150%;
  text-transform: uppercase;
  cursor: pointer;
}
button.l{
  padding: 16.5px 24px;
  font-size: 18px;
}
button.color{
  color: var(--black);
  background-color: var(--accent);
  border: none;
}
button.white-stroke{
  color: var(--white);
  background-color: transparent;
  border: 1.5px solid var(--white);
}
button.white-stroke:hover{
  background-color: rgba(255, 255, 255, 0.1);
}
button.white-stroke:active{
  background-color: var(--text-white);
  color: var(--black)
}
button.color:hover{
  background-color: #D8B2FF;
}
button.color:active{
  background-color: #AC66F4;
}
a{
  text-decoration: none;
}
a:hover{
  text-decoration: underline;
}
#root{
  display: flex;
  flex-direction: column;
  min-height: 100vh;
}
.container{
  padding-left: 64px;
  padding-right: 64px;
  max-width: 1440px;
  margin: 0 auto;
  position: relative;
}
.row{
  display: flex;
  flex-direction: row;
}
.grecaptcha-badge {
  visibility: hidden;
}

/*Header*/
.logo{
  cursor: pointer;
}
header .header-inner{
  width: 100%;
  height: 112px;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
header .logo{
  width: 113px;
}
header nav{
  display: flex;
  gap: 42px;
}
header nav a{
  font-weight: 400;
  font-size: 18px;
  line-height: 150%;
  color: var(--text-white);
}
header .buttons-wrapper{
  gap: 16px;
}
header .additional-buttons {
  display: none;
}

/*Footer*/
footer{
  width: 100%;
  padding: 80px 0;
  margin-top: auto;
}
footer .navigation {
  display: flex;
  justify-content: space-between;
  margin-bottom: 80px;
}
footer .navigation .logo{
  width: 113px;
}
footer .navigation nav{
  display: flex;
  gap: 32px;
}
footer .navigation nav a{
  font-weight: 500;
  font-size: 16px;
  line-height: 150%;
  color: var(--light-purple);
  opacity: 0.9;
}
footer .additional-navigatoin span,
footer .additional-navigatoin nav a{
  font-weight: 500;
  font-size: 14px;
  line-height: 150%;
  color: var(--light-purple);
  opacity: 0.9;
}
footer .navigation .social-links{
  display: flex;
  gap: 20px;
}
footer .additional-navigatoin,
footer .additional-navigatoin nav{
  display: flex;
  gap: 24px;
}
footer .additional-navigatoin{
  justify-content: center;
  align-items: center;
}
footer .additional-navigatoin nav a{
  text-decoration: underline;
}

/*NOT FOUND*/
.not-found .numbers{
  font-weight: 100;
  font-size: 250px;
  line-height: 315px;
  text-align: center;
  color: var(--purple);
  margin-top: 41px;
  opacity: 0.8;
}
.not-found .text{
  font-weight: 300;
  font-size: 24px;
  line-height: 28px;
  text-align: center;
  color: var(--purple);
  margin-top: -14px;
  opacity: 0.8;
}
.not-found button{
  margin: 50px auto 0;
}

/*Soon*/
.soon{
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
}
.soon img{
  width: 154px;
  margin-bottom: 7px;
}
.soon h1{
  font-weight: 100;
  font-size: 50px;
  line-height: 63px;
  color: var(--text-white);
  margin-bottom: 10px;
  opacity: 0.8;
}
.soon p {
  font-weight: 300;
  font-size: 24px;
  line-height: 28px;
  text-align: center;
  color: var(--purple);
  margin-bottom: 29px;
  opacity: 0.8;
}

/*Home*/
/*Home info*/
.home-info{
  padding: 117px 0 153px;
}
.home-info h1{
  max-width: 835px;
  font-weight: 500;
  font-size: 128px;
  line-height: 116px;
  color: var(--text-white);
  text-shadow: 0 4px 9px rgba(0, 0, 0, 0.1);
  margin-bottom: 44px;
}
.home-info p{
  max-width: 515px;
  font-weight: 300;
  font-size: 24px;
  line-height: 32px;
  color: var(--purple);
  margin-bottom: 30px;
}
.home-info button{
  display: flex;
  align-items: center;
}
.home-info button img{
  margin-left: 12px;
}
img[alt="wallet"]{
  position: absolute;
  bottom: 0;
  right: 0;
  width: 646px;
  z-index: -1;
}

/*Services*/
.services{
  background: var(--black);
  background-position: top right;
  background-image: url("./assets/snow-cristal.png");
  background-repeat: no-repeat;
  background-size: 280px;
  padding: 112px 0 123px;
  border: 1px solid rgba(136, 105, 168, 0.2);
  box-shadow: 0 4px 44px rgba(0, 0, 0, 0.1);
}
.services .services-info{
  max-width: 50%;
  padding-left: 64px;
}
.services h2{
  font-weight: 400;
  font-size: 48px;
  line-height: 120%;
  color: var(--text-white);
  margin-bottom: 24px;
}
.services h2{
  font-weight: 400;
  font-size: 48px;
  line-height: 120%;
  color: var(--text-white);
  margin-bottom: 24px;
}
.services p{
  font-weight: 300;
  font-size: 18px;
  line-height: 150%;
  color: var(--purple);
}
.services .services-list{
  padding-top: 118px;
  display: flex;
  gap: 48px;
}
.services-list img{
  margin-bottom: 36px;
}
.services-list h3{
  font-weight: 400;
  font-size: 32px;
  line-height: 120%;
  color: var(--text-white);
  margin-bottom: 24px;
}
.services-list p{
  font-weight: 300;
  font-size: 16px;
  line-height: 150%;
  color: var(--purple);
}

/*Why*/
.why {
  margin: 101px 0 120px;
}
.why h2{
  margin-bottom: 90px;
  font-weight: 500;
  font-size: 64px;
  line-height: 120%;
  text-align: center;
  color: var(--text-white);
}
.reasons-list{
  background-color: rgba(255, 255, 255, 0.03);
  position: relative;
}
.reasons-list .bg{
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: -1;
  width: calc(100% + 128px);
}
.reasons-list .reason-row{
  display: flex;
  border: 1px solid var(--dark-purple);
}
.reasons-list .reason-row:first-child{
  border-bottom: none;
}
.reasons-list .reason{
  width: 50%;
  padding: 56px 48px 68px;
  gap: 10px;
}
.reasons-list .reason:first-child{
  border-right: 1px solid var(--dark-purple);
}
.reasons-list .reason img{
  height: 100px;
  margin-bottom: 30px;
}
.reasons-list .reason h4{
  font-weight: 400;
  font-size: 34px;
  line-height: 120%;
  color: var(--text-white);
  margin-bottom: 16px;
}
.reasons-list .reason p{
  font-weight: 300;
  font-size: 18px;
  line-height: 150%;
  color: var(--purple);
}

/*Apply*/
.apply{
  padding: 112px 64px;
  background: var(--black);
  border: 1px solid rgba(136, 105, 168, 0.2);
  box-shadow: 0 4px 44px rgba(0, 0, 0, 0.1);
  background-image: url("./assets/square-snow-cristal.png");
  background-position: bottom left;
  background-repeat: no-repeat;
  background-size: 202px;
  display: flex;
  margin-bottom: 59px;
}
.apply .apply-info,
.apply .apply-structure{
  flex: 1;
}
.apply .apply-info h2{
  font-weight: 400;
  font-size: 48px;
  line-height: 120%;
  color: var(--text-white);
  margin-bottom: 16px;
}
.apply .apply-info p{
  font-weight: 300;
  font-size: 18px;
  line-height: 150%;
  color: var(--purple);
  margin-bottom: 32px;
  max-width: 323px;
}
.apply .apply-segment{
  display: flex;
  margin-bottom: 16px;
}
.apply .apply-segment:last-child{
  margin-bottom: 0;
}
.apply .apply-segment .apply-segment-image{
  width: 45px;
  margin-right: 50px;
  display: flex;
  flex-direction: column;
  align-items: center;
}
.apply .apply-segment .apply-segment-image img{
  width: 45px;
  margin-bottom: 16px;
}
.apply .apply-segment .apply-segment-image .separator{
  width: 0;
  height: 100px;
  border: 1px solid #644F7A;
}
.apply .apply-segment .apply-segment-title{
  font-weight: 400;
  font-size: 30px;
  line-height: 120%;
  color: var(--text-white);
  margin-bottom: 10px;
}
.apply .apply-segment .apply-segment-description{
  font-weight: 300;
  font-size: 16px;
  line-height: 150%;
  color: var(--purple);
}

/*About us*/
/*Preview*/
.preview{
  width: 100%;
  background: var(--black-gradient);
  border: 1px solid rgba(136, 105, 168, 0.2);
  box-shadow: 0 4px 44px rgba(0, 0, 0, 0.1);
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 141px 0;
}
.preview-inner {
  max-width: 716px;
}
.preview-inner h1{
  font-weight: 500;
  font-size: 64px;
  line-height: 120%;
  text-align: center;
  color: var(--text-white);
}
.preview-inner p{
  font-weight: 300;
  font-size: 22px;
  line-height: 150%;
  color: var(--purple);
  text-align: center;
  margin-top: 24px;
}

/*Benefits*/
.benefits{
  padding: 159px 0 148px;
}
.benefits h1{
  font-weight: 500;
  font-size: 54px;
  line-height: 120%;
  text-align: center;
  color: var(--text-white);
  max-width: 500px;
  margin: 0 auto 90px;
}

/*Goals*/
.goals{
  padding: 0 64px 131px;
}
.goals .goal{
  margin: 0 auto 79px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.goals .goal:last-child{
  margin: 0 auto;
}
.goals .goal img{
  height: 336px;
  margin-right: 28px;
}
.goals .goal .goal-text h2 {
  font-weight: 400;
  font-size: 54px;
  line-height: 120%;
  color: var(--text-white);
  margin-bottom: 16px;
}
.goals .goal .goal-text p {
  font-weight: 300;
  font-size: 24px;
  line-height: 150%;
  color: var(--purple);
  max-width: 729px;
}

/*Our Services*/
.our-services h1{
  padding: 79px 0 113px;
  font-weight: 500;
  font-size: 64px;
  line-height: 120%;
  text-align: center;
  color: var(--text-white);
}
.our-services .our-service{
  display: flex;
  align-items: center;
  margin-bottom: 48px;
}
.our-services .our-service:last-child{
  margin-bottom: 152px;
}
.our-services .our-service div:first-child{
  margin-right: 112px;
}
.our-services .our-service img{
  width: 534px;
  aspect-ratio: 1/1;
}
.our-services .our-service .our-service-content{
  display: flex;
  flex-direction:column;
  justify-content: center;
  align-items: flex-start;
}
.our-services .our-service .our-service-content h2{
  font-weight: 400;
  font-size: 54px;
  line-height: 120%;
  color: var(--text-white);
  margin-bottom: 24px;
}
.our-services .our-service .our-service-content p{
  font-weight: 300;
  font-size: 22px;
  line-height: 150%;
  color: var(--purple);
  margin-bottom: 40px;
}

/*Contact Us*/
.contact-us {
  padding-top: 112px;
  padding-bottom: 112px;
  display: flex;
  gap: 80px;
}
.contact-us > div{
  width: 50%;
}
.contact-us .contact-information,
.contact-us .contact-form form{
  display: flex;
  flex-direction: column;
}
.contact-us .contact-information h1{
  font-weight: 500;
  font-size: 48px;
  line-height: 120%;
  color: var(--text-white);
  margin-bottom: 24px;
}
.contact-us .contact-information h2{
  font-weight: 400;
  font-size: 24px;
  line-height: 120%;
  color: var(--purple);
  margin-bottom: 24px;
}
.contact-us .contact-information p{
  font-weight: 300;
  font-size: 16px;
  line-height: 150%;
  color: var(--purple);
}
.contact-us .contact-information p.marg{
  margin-bottom: 40px;
}
.contact-us .contact-information a{
  margin-top: 16px;
  display: flex;
  align-items: center;
  font-weight: 300;
  font-size: 16px;
  line-height: 150%;
  color: var(--purple);
}
.contact-us .contact-information a:last-child{
  align-items: flex-start;
}
.contact-us .contact-information a img{
  margin-right: 18px;
}
.contact-us .contact-information a span{
  max-width: 355px;
}
.contact-us .contact-form form .form-row{
  display: flex;
  flex-direction: column;
  margin-bottom: 24px;
  position: relative;
}
.contact-us .contact-form form .form-row:last-child{
  margin-bottom: 0;
}
.contact-us .contact-form form .form-row input:not([type="checkbox"]),
.contact-us .contact-form form .form-row textarea{
  margin-top: 8px;
  padding: 12px;
  width: 100%;
  height: 48px;
  background: #453E4B;
  border: 1px solid var(--light-purple);
  font-weight: 300;
  font-size: 16px;
  line-height: 150%;
  color: var(--text-white)
}
.contact-us .contact-form form .form-row input[type="checkbox"]{
  height: 18px;
  width: 18px;
  margin-right: 12px;
}
.contact-us .contact-form form .form-row input:focus,
.contact-us .contact-form form .form-row textarea:focus{
  outline: 1px solid var(--light-purple);
}
.contact-us .contact-form form .form-row textarea{
  height: 180px;
  resize: none;
}
.contact-us .contact-form form .form-row textarea::placeholder{
  font-weight: 300;
  font-size: 16px;
  line-height: 150%;
  color: var(--text-white);
}
.contact-us .contact-form form .form-row label{
  font-weight: 300;
  font-size: 16px;
  line-height: 150%;
  color: var(--text-white);
  display: flex;
  align-items: center;
  opacity: 0.8;
}
.contact-us .contact-form form .form-row span{
  font-weight: 300;
  font-size: 14px;
  line-height: 150%;
  color: #ff0000b5;
  margin-top: 4px;
  position: absolute;
  bottom: -21px;
}
.contact-us .contact-form form button{
  margin-top: 16px;
  align-self: flex-start;
}
.contact-us .contact-form p{
  margin-top: 40px;
  font-weight: 300;
  font-size: 12px;
  line-height: 150%;
  color: var(--purple);
}
.contact-us .contact-form p a{
  color: var(--text-white);
  text-decoration: underline;
}

/*Policy*/
.policy-container{
  margin: 0 auto;
  padding: 64px 130px 84px;
  font-weight: 300;
  font-size: 16px;
  line-height: 135%;
  color: var(--purple);
}
.policy-container h1{
  max-width: 910px;
  font-weight: 400;
  font-size: 32px;
  line-height: 130%;
  color: var(--text-white);
}
.policy-container h2{
  max-width: 910px;
  font-weight: 400;
  font-size: 24px;
  line-height: 130%;
  color: var(--text-white);
  margin-top: 40px;
}
.policy-container h3{
  max-width: 910px;
  font-weight: 400;
  font-size: 18px;
  line-height: 150%;
  color: var(--text-white);
}
.policy-container > p{
  max-width: 910px;
  margin-top: 20px;
}
.policy-container > p span{
  margin-left: 30px;
  display: inline-block;
}
.policy-container a{
  color: var(--text-white);
  text-decoration: underline;
  margin: 0 4px;
  word-break: break-all;
}

@media screen and (max-width: 1250px) {
  img[alt="wallet"]{
    position: absolute;
    bottom: 0;
    right: -25px;
    width: 56%;
  }
}
@media screen and (max-width: 991px) {
  button{
    padding: 9.5px 20px;
  }
  .container {
    padding-left: 16px;
    padding-right: 16px;
  }
  .policy-container{
    padding: 30px 20px 40px;
    max-width: unset;
  }

  /*Header*/
  header .header-inner {
    height: 72px;
  }
  header .logo {
    width: 104px;
  }
  header nav {
    display: none;
  }
  header .logo-wrapper {
    display: flex;
    justify-content: space-between;
    width: 100%;
  }
  header .additional-buttons {
    display: flex;
    gap: 36px;
  }
  header .buttons-wrapper,
  header.opened .additional-buttons .white-stroke{
    display: none;
  }
  header.opened{
    position: fixed;
    left: 0;
    top: 0;
    height: 100%;
    width: 100%;
    background: var(--purple-gradient);
    z-index: 1;
  }
  header.opened .header-inner {
    display: flex;
    flex-direction: column;
  }
  header.opened .logo-wrapper {
    margin-top: 24px;
  }
  header.opened nav {
    display: flex;
    flex-direction: column;
    margin-top: 159px;
    align-items: center;
  }
  header.opened .buttons-wrapper{
    display: flex;
    margin-top: auto;
    width: 100%;
    margin-bottom: 16px;
  }
  header.opened .buttons-wrapper button{
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 16.5px 23.5px;
    font-size: 18px;
    line-height: 150%;
    white-space: nowrap;
    flex: 1;
  }
  header.opened .container,
  header.opened .header-inner{
    height: 100%;
  }

  /*Footer*/
  footer {
    padding: 52px 0;
  }
  footer .navigation,
  footer .navigation nav,
  footer .additional-navigatoin nav{
    flex-direction: column;
    align-items: center;
  }
  footer .navigation .logo{
    width: 75px;
  }
  footer .navigation{
    margin-bottom: 44px;
  }
  footer .navigation nav{
    margin-top: 44px;
    gap: 14px;
  }
  footer .additional-navigatoin{
    flex-direction: column-reverse;
  }
  footer .additional-navigatoin nav{
    gap: 24px;
  }
  footer .navigation .social-links{
    display: none;
  }

  /*NOT FOUND*/
  .not-found .numbers{
    font-weight: 100;
    font-size: 130px;
    line-height: 164px;
    margin-top: 97px;
  }
  .not-found .text{
    font-size: 18px;
  }
  .not-found button {
    margin: 33px auto 48px;
  }

  /*Soon*/
  .soon img{
    width: 122px;
    margin-bottom: 13px;
  }
  .soon h1{
    margin-bottom: 7px;
  }
  .soon p {
    font-size: 18px;
    line-height: 28px;
    margin-bottom: 19px;
  }

  /*Home*/
  /*Home info*/
  .home-info{
    padding: 70px 0 0;
  }
  .home-info h1{
    font-size: 60px;
    line-height: 60px;
    margin-bottom: 24px;
  }
  .home-info p{
    font-size: 14px;
    line-height: 20px;
    margin-bottom: 24px;
  }
  .home-info button{
    width: 100%;
    justify-content: center;
  }
  img[alt="wallet"]{
    display: flex;
    position: relative;
    right: 0;
    width: 100%;
    margin-top: 25px;
  }
  /*Services*/
  .services{
    background-size: 100px;
    padding: 90px 0 60px;
  }
  .services .services-info{
    max-width: unset;
    padding-left: 16px;
    padding-right: 16px;
  }
  .services h2{
    font-size: 40px;
    line-height: 46px;
  }
  .services p{
    font-size: 16px;
    line-height: 150%;
  }
  .services .services-list{
    padding-top: 72px;
    gap: 70px;
    flex-direction: column;
  }
  .services-list img{
    margin-bottom: 32px;
  }
  .services-list h3{
    font-size: 28px;
    line-height: 120%;
    margin-bottom: 20px;
  }
  .services-list p{
    font-size: 14px;
  }

  /*Why*/
  .why {
    margin: 120px 0 81px;
  }
  .why h2{
    margin-bottom: 44px;
    font-size: 44px;
  }
  .reasons-list .bg{
    display: none;
  }
  .reasons-list .bg{
    display: none;
  }
  .reasons-list .reason-row{
    flex-direction: column;
    border: 1px solid var(--dark-purple);
  }
  .reasons-list .reason-row:last-child{
    border-bottom: 1px solid var(--dark-purple);
  }
  .reasons-list .reason{
    width: 100%;
    padding: 36px 23px 42px;
    gap: 10px;
  }
  .reasons-list .reason:first-child{
    border-right: none;
    border-bottom: 1px solid var(--dark-purple);
  }
  .reasons-list .reason img{
    height: 84px;
  }
  .reasons-list .reason h4{
    font-size: 28px;
    margin-bottom: 16px;
  }
  .reasons-list .reason p{
    font-size: 14px;
  }

  /*Apply*/
  .apply{
    flex-direction: column;
    padding: 54px 16px;
    background-size: 84px;
    margin-bottom: 0;
  }
  .apply .apply-info h2{
    font-size: 40px;
    line-height: 46px;
    margin-bottom: 24px;
  }
  .apply .apply-info p{
    font-size: 16px;
    margin-bottom: 24px;
    max-width: unset;
  }
  .apply .apply-info button{
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .apply .apply-structure{
    margin-top: 60px;
    margin-bottom: 139px;
  }
  .apply .apply-segment .apply-segment-image{
    width: 36px;
    margin-right: 16px;
  }
  .apply .apply-segment .apply-segment-image img{
    width: 36px;
  }
  .apply .apply-segment .apply-segment-title{
    font-size: 24px;
    line-height: 120%;
  }

  /*About us*/
  /*Preview*/
  .preview{
    padding: 90px 16px 100px;
  }
  .preview-inner {
    max-width: 330px;
  }
  .preview-inner h1{
    font-size: 48px;
    line-height: 60px;
  }
  .preview-inner p{
    font-size: 14px;
    line-height: 20px;
    margin-top: 24px;
  }

  /*Benefits*/
  .benefits{
    padding: 124px 0 56px;
  }
  .benefits h1{
    font-size: 36px;
    max-width: 330px;
    margin: 0 auto 44px;
  }

  /*Goals*/
  .goals{
    padding: 0 39px 48px;
  }
  .goals .goal{
    margin: 0 auto 56px;
    flex-direction: column;
    align-items: flex-start;
  }
  .goals .goal:last-child{
    margin: 0 auto;
  }
  .goals .goal img{
    height: 208px;
    margin-right: 0;
    margin-bottom: 16px;
  }
  .goals .goal .goal-text h2 {
    font-size: 28px;
  }
  .goals .goal .goal-text p {
    font-size: 14px;
  }

  /*Our Services*/
  .our-services h1{
    padding: 90px 0 64px;
    font-size: 48px;
    line-height: 60px;
  }
  .our-services .our-service{
    display: flex;
    align-items: flex-start;
    flex-direction: column;
    margin-bottom: 48px;
  }
  .our-services .our-service:last-child{
    margin-bottom: 55px;
  }
  .our-services .our-service div:first-child{
    margin-right: 0;
  }
  .our-services .our-service:nth-child(3){
    flex-direction: column-reverse;
  }
  .our-services .our-service img{
    width: 208px;
    margin-bottom: 16px;
  }
  .our-services .our-service .our-service-content{
    display: flex;
    flex-direction:column;
    justify-content: center;
    align-items: flex-start;
  }
  .our-services .our-service .our-service-content h2{
    font-weight: 400;
    font-size: 28px;
    margin-bottom: 16px;
  }
  .our-services .our-service .our-service-content p{
    font-size: 14px;
    margin-bottom: 24px;
  }
  .our-services .our-service .our-service-content button{
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    font-weight: 500;
    font-size: 18px;
    line-height: 150%;
    padding: 10.5px 0;
  }

  /*Contact Us*/
  .contact-us {
    padding-top: 90px;
    padding-bottom: 50px;
    display: flex;
    flex-direction: column;
    gap: 28px;
  }
  .contact-us > div{
    width: 100%;
  }
  .contact-us .contact-information h1{
    font-weight: 500;
    font-size: 48px;
    line-height: 60px;
    margin-bottom: 22px;
    text-align: center;
  }
  .contact-us .contact-information h2{
    font-size: 20px;
    line-height: 150%;
    margin-bottom: 0;
  }
  .contact-us .contact-information p{
    margin-top: 16px;
  }
  .contact-us .contact-information p.marg{
    margin-bottom: 0;
  }
  .contact-us .contact-information a{
    margin-top: 16px;
  }
  .contact-us .contact-form form button{
    margin-top: 16px;
    align-self: unset;
    padding: 16.5px 0;
    display: flex;
    align-items: center;
    justify-content: center;
    font-weight: 500;
    font-size: 18px;
    line-height: 150%;
  }
}
@media screen and (max-width: 323px) {
  header.opened .buttons-wrapper button{
    white-space: normal;
  }
}



